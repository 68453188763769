import $ from 'jquery';
import { Modal } from './modal';

class SelectCountyModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${this.modalId}"]`),
      isContentHeightDynamic: true,
    });
  }

  init = () => {
    this.modal.init();
  }
}

export {
  SelectCountyModal,
};
